<div class="sidepanel">
  <div class="logo logo-expanded" *ngIf="sideNav?.isOpen">
    <div style="display: flex; align-items: center; width: 100%; margin: 20px">
      <span style="padding: none"
        ><img
          style="display: flex; justify-content: flex-start"
          src="assets/custom/traq-workflow-log.svg"
          alt="traq-quality-logo"
        />
      </span>
    </div>
  </div>

  <div class="logo" style="width: 100%" *ngIf="!sideNav?.isOpen">
    <div>
      <span style="padding: none">
        <img
          style="display: flex; justify-content: flex-start"
          src="assets/custom/sidenav-search.svg"
          alt="traq-quality-logo"
        />
      </span>
    </div>
  </div>

  <div style="display: flex; height: 100%" class="vertical-bar-main-container">
    <div class="vericalBar">
      <div class="primary-links">
        <div
          class="collection-button"
          routerLink="main-areas"
          [class.sidenav-active]="
            getClass('/main-areas') || getClass('/objectives')
          "
        >
          <img
            [src]="
              getClass('/main-areas') || getClass('/objectives')
                ? 'assets/custom/collection-active.svg'
                : 'assets/custom/collection-inactive.svg'
            "
            alt="collection"
          />
          <label class="montserrat-semibold"> {{ AuditArea }} </label>
        </div>

        <div
          class="collection-button"
          routerLink="notifications"
          [class.sidenav-active]="getClass('/notifications')"
        >
          <img
            [src]="
              getClass('/notifications')
                ? 'assets/Notifications icons/icon-inbox-active.svg'
                : 'assets/Notifications icons/Icon-inbox.svg'
            "
            alt="inbox"
            style="width: 22px"
          />
          <span
            *ngIf="notificationCount > 0 && notificationCount < 99"
            class="e-badge e-badge-danger e-badge-overlap e-badge-notification notification-badge"
            >{{ notificationCount }}</span
          >
          <span
            *ngIf="notificationCount > 0 && notificationCount >= 99"
            class="e-badge e-badge-danger e-badge-overlap e-badge-notification notification-badge"
            >99+</span
          >
          <label class="montserrat-semibold"> Inbox </label>
        </div>

        <div
          class="collection-button"
          routerLink="respond"
          [class.sidenav-active]="
            getClass('/respond') || getClass('/questionnaire-help')
          "
        >
          <img
            [src]="
              getClass('/respond') || getClass('/questionnaire-help')
                ? 'assets/custom/respond-active.svg'
                : 'assets/custom/respond-inactive.svg'
            "
            alt="collection"
            style="width: 28px"
          />
          <label class="montserrat-semibold"> Help Assist </label>
        </div>

        <div
          class="collection-button"
          routerLink="dashboard"
          [class.sidenav-active]="
            getClass('/dashboard') || getClass('/dashboard')
          "
          *ngxPermissionsOnly="'ADM_ADM_CAN_MANAGE_SYS_SETTINGS'"
        >
          <img
            [src]="
              getClass('/dashboard') || getClass('/dashboard')
                ? 'assets/custom/dashboard-active.svg'
                : 'assets/custom/dashboard-inactive.svg'
            "
            alt="collection"
            style="width: 28px"
          />
          <label class="montserrat-semibold"> Dashboard </label>
        </div>

        <div
          class="collection-button"
          routerLink="audit-trail"
          [class.sidenav-active]="
            getClass('/audit-trail') || getClass('/audit-trail')
          "
          *ngxPermissionsOnly="'ADM_ADM_CAN_MANAGE_SYS_SETTINGS'"
        >
          <img
            [src]="
              getClass('/audit-trail') || getClass('/audit-trail')
                ? 'assets/custom/audit-trail-newactive.svg'
                : 'assets/custom/audit-trail-newinactive.svg'
            "
            alt="collection"
          />
          <label class="montserrat-semibold"> Audit Trail </label>
        </div>
        <div
        class="collection-button"
        routerLink="groups"
        [class.sidenav-active]="
          getClass('/groups') || getClass('/groups')
        "
        *ngxPermissionsOnly="'ADM_ADM_CAN_MANAGE_SYS_SETTINGS'"
      >
        <img
          [src]="
            getClass('/groups') || getClass('/groups')
              ? 'assets/custom/group-active.png'
              : 'assets/custom/group-inactive.png'
          "
          alt="collection"
        />
        <label class="montserrat-semibold"> Group </label>
      </div>
      </div>

      <div class="secondory-links">
        <div
          class="collection-button"
          routerLink="admin"
          [class.sidenav-active]="getClass('/admin')"
          *ngxPermissionsOnly="'ADM_ADM_CAN_MANAGE_SYS_SETTINGS'"
        >
          <img
            [src]="
              getClass('/admin')
                ? 'assets/custom/admin-active.svg'
                : 'assets/custom/admin-inactive.svg'
            "
            alt="inbox"
          />
          <label class="montserrat-semibold"> Admin </label>
        </div>

        <button
          (click)="openProfileDialog()"
          class="myInfo-button collection-button"
          [class.sidenav-active]="
            getClass('/profile') ||
            getClass('/contact') ||
            getClass('/faq') ||
            getClass('/about-us')
          "
        >
          <div>
            <img
              [src]="
                getClass('/profile') ||
                getClass('/contact') ||
                getClass('/faq') ||
                getClass('/about-us')
                  ? 'assets/custom/profile-active.svg'
                  : 'assets/custom/profile-inactive.svg'
              "
              alt="inbox"
            />
            <label class="montserrat-semibold"> My Info </label>
          </div>
        </button>
      </div>
    </div>
    <app-profile-modal></app-profile-modal>
  </div>
  <div id="toast" #toast></div>
</div>
