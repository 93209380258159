import { Pipe, PipeTransform } from '@angular/core';
import * as _moment from 'moment';
@Pipe({
    name: 'datePipe'
})
export class DatePipe implements PipeTransform {

    transform(input: string, formatType: string): any {
        const me = this;
        const format = me.dateFormat(formatType);

        if (!input) {
            return null;
        }

    
        return _moment.utc(input).local().format(format);
    }

    dateFormat(formatType: string): string {

        switch (formatType) {
            case 'shortDate':
                return 'DD MMM YYYY';
            case 'normalDate':
                return 'DD MMM YY';
            case 'normalDateWithTime':
                return 'd/M/yy h:mm a';
            case 'normalTimeWithDate':
                return 'h:mm A, DD/MM/yyyy';
            case 'normalTimeWithDateComma':
                return 'hh:mm A, DD MMM yyyy';
            case 'monthFirstWithDate':
                return 'MMM DD,YYYY hh:mm:ss A'
            case 'normalTimeWithDateNoComma':
                return 'h:mm A DD/MM/yyyy';
            case 'fullTimeAndDate':
                return 'DD MMM YY HH:mm';
            case 'mediumDate':
                return 'DD MMM YYYY  hh:mm A';
            case 'longDate':
                return 'DD MMM YYYY hh:mm:ss A';
            case 'normalDateWithDay':
                return 'dddd, d/M/yy';
            case 'yearMonthDate':
                return 'YY-MM-DD';
            case 'longTime':
                return 'hh:mm:ss A';
            case 'mediumTime':
                return 'hh:mm A';
            case 'FullYearDateAndTime':
                return 'YYYY-MM-DD HH:mm:ss';
            case 'FullYearDate':
                return 'YYYY-MM-DD';
            case 'timeWithDate':
                return 'hh:mm A | DD MMM'
        }
    }

}

export const DateFormat = {
    parse: {
        dateInput: 'input',
    },
    display: {
        dateInput: 'DD MMM YY',
        monthYearLabel: 'MMM YYYY'
    }
};

export const GlobalMatDatePickerFormatProvider = [

];

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {

    transform(value: any, args?: any): any {

        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

            // const hour = Math.floor((+new Date() - +new Date(value)) / (h * 60 * 60 * 1000));

            if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
                return 'just now';
            // if (seconds > 3599 && seconds < 7200)
            //     return '1 hour ago'
            // if (seconds > 3600 && seconds < 86400) {
            //     return 'today';
            // }
            const intervals = {
                'year': 31536000,
                'month': 2592000,
                'week': 604800,
                'day': 86400,
                'hour': 3600,
                'minute': 60,
                'second': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        return counter + ' ' + i + ' ago'; // singular (1 day ago)
                    } else {
                        return counter + ' ' + i + 's ago'; // plural (2 days ago)
                    }
            }
        }
        return value;
    }
}


@Pipe({
  name: 'customDateFormat'
})
export class customDateFormat implements PipeTransform {
  transform(value: any, args?: any): any {

    const now = new Date(); const hours = now.getHours() % 12 || 12; 
    // Convert to 12-hour format 
    const minutes = String(now.getMinutes()).padStart(2, '0'); 
    // Pad with zero 
    const meridian = now.getHours() >= 12 ? 'PM' : 'AM'; 
    const day = now.getDate(); 
    const month = now.toLocaleString('default', { month: 'long' }); 
    // Full month name 
    const year = now.getFullYear();
    const format: any = '';


    if (!value || typeof value !== 'string') {
      return ''; // Return empty if the input is invalid
    }

    // Check if the date string includes "IST"
    const isIST = value.includes('IST');

    // Parse the date string into a Date object
    // Assuming dateString is in the format "Tue Dec 05 14:15:30 IST 2023"
    const date = new Date(value);

    if (isNaN(date.getTime())) {
      return 'Invalid Date'; // Handle invalid dates gracefully
    }

    // Format 1: "hh:mm A, DD MMM yyyy"
    if (format == '1') {
      const hours = this.padZero(date.getHours() % 12 || 12); // Convert to 12-hour format
      const minutes = this.padZero(date.getMinutes());
      const meridian = date.getHours() >= 12 ? 'PM' : 'AM';
      const day = this.padZero(date.getDate());
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear();

      return '${hours}:${minutes} ${meridian}, ${day} ${month} ${year}';
    }

    // Format 2: "%I:%M %p, %d %b %Y"
    if (format == '2') {
      const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      };

      // Use Intl.DateTimeFormat to format the date
      return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    return 'Invalid Format'; // Handle unsupported formats gracefully
  }

  // Helper function to pad numbers with leading zeros
  private padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}    





