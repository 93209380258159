import { Injectable } from "@angular/core";
import { Toast, ToastModel } from "@syncfusion/ej2-notifications";
import { CustomToastModel, SnakbarType } from "./snakbar.model";
import { beforeOpen } from "@syncfusion/ej2-angular-grids";

@Injectable({
  providedIn: "root",
})
export class SnakbarService {
  public toastInstance: Toast;
  public toastObj: Toast;

  constructor() { }

  createToast: Function = (element: HTMLElement, model: ToastModel): Toast => {
    // if (!element.classList.contains("e-toast")) {
    //   this.toastObj = new Toast(model, element);
    // }
    if(this.toastObj){
    this.toastObj.destroy();
    this.toastObj= null;

    }
    this.toastObj = new Toast(model, element);
    return this.toastObj;
  };

  showToast: Function = (element: HTMLElement, cusModel: CustomToastModel) => {
    const model: CustomToastModel = {
      title: cusModel?.title,
      content: cusModel?.content,
      cssClass: SnakbarType[cusModel?.type],
      position: cusModel?.position ? cusModel?.position : { X: "Right" },
      timeOut: cusModel?.timeOut || 3000,
      showCloseButton: cusModel?.showCloseButton ? cusModel?.showCloseButton : true
      // icon: SnakbarIcon[cusModel?.type],
    };

    // if(this.toastObj){
    //   this.toastObj.hide();
    //   }


    this.toastInstance = this.createToast(element, {
      showCloseButton: true,
      ...model,
    });
    this.toastInstance.show();
    this.destroyToast();
  };

  hideToast: Function = () => {
    if (this.toastInstance) {
      this.toastInstance.hide();
    }
  };

  hideToastAll: Function = () => {
    if (this.toastInstance) {
      this.toastInstance.hide("All");
    }
  };

  destroyToast: Function = () => {
    setTimeout(() => {
      if (this.toastInstance) {
        this.toastInstance.destroy();
      }
    }, 3000);
  };
}
