import { statusHandler } from "src/app/utility/status-handler.util";
import { NavigationEnd, Router } from "@angular/router";
import {
  LogoutLoadingState,
  LogoutLoadedState,
  LogoutErrorState,
} from "./../../core/session/session.state";
import { Store } from "src/app/core/store/store";
import { SessionService } from "src/app/core/session/session.service";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { MyNotificationService } from "src/app/pages/notifications/service/my-notification.service";
import * as moment from "moment";
import { SidebarComponent } from "@syncfusion/ej2-angular-navigations";
import { ProfileModalComponent } from "../modal/profile-modal/profile-modal.component";
import { MessagingService } from "src/app/core/messaging/messaging.service";
import { MessagingMessage } from "src/app/core/messaging/messaging.model";
import { AppLocalStorage } from "src/app/utility/local-storage.util";
import { SnakbarService } from "../common/snakbar/snakbar.service";
import { GlobalConstants } from "../common/global-string-constants/global-string-constants";
//import { MyNotificationPayload } from "src/app/pages/notifications/service/my-notification.model";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SidenavComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoggedIn$: Observable<boolean>;

  isOpen: boolean = false; // open collection overlay.

  data: any;
  error: boolean;
  empty: boolean;
  loading: boolean;
  @ViewChild("toast") toast: ElementRef;

  @ViewChild("sideNav")
  public sideNav: SidebarComponent;

  @ViewChild(ProfileModalComponent) public profileModal: ProfileModalComponent;

  // Global Constants
  AuditArea: string = GlobalConstants.AuditArea;

  notificationData: any;
  notificationEmpty: boolean;
  notificationError: boolean;
  notificationLoading: boolean;
  notificationCount: any;
  logoPath: Observable<string>;
  FirmName: string;
  proRole: any;
  tabLinksLabel: any;
  firstUserLoggedIn: boolean = false;
  isUserGuideCompleted: boolean;
  notification: any = [];
  dates: any;
  isShow: boolean = true;
  filterText: any;
  accountDetails: any;
  queryString: string;
  receiverId: string;
  fromDate: string;
  toDate: string;
  userGuideText: any;
  pagination: {
    p: number;
    pp: number;
  };
  field: string;
  order: string;

  // selected='value';
  value;

  defaultnotificationData: any;

  NotificationData;
  newNotification: Observable<any>;
  NotificationId: string;
  PresentNotification: boolean;
  isDisabledOn = true;

  selectedDay: any = "30";

  fullName: Observable<string>;

  statusClass = "";
  notificationSubscriber: Subscription; //for socket
  private routerSubscription: Subscription;

  constructor(
    private messaging: MessagingService,
    private snakbarService: SnakbarService,
    public session: SessionService,
    public notificationService?: MyNotificationService,
    private router?: Router,
    private cd?: ChangeDetectorRef,
    private navigator?: Router
  ) { }

  ngOnDestroy(): void {
    const me = this;
    me.notificationSubscriber.unsubscribe();
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  tabLinks: object[]; //TODO: add datatype for this
  settingTabLinks: object[];

  ngOnInit(): void {
    const me = this;
    const url = "/notify/unread-count/" + me.session.account.id;
  //  me.notificationCount = localStorage?.getItem("notificationCount");
    me.notificationSubscriber = me.messaging
      .watch(url)
      .subscribe((messages: MessagingMessage) => {
        me.notificationCount = messages.count;
      });
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.closeProfileDialog();
      }
    });
    me.firstUserLoggedIn = me.session?.account?.hasUserLoggedInFirstTime;
    me.isUserGuideCompleted = me.session?.account?.isUserGuideCompleted;

    me.tabLinks = [
      {
        label: "notifications",
        link: "/notifications",
      },
    ];

    me.logoPath = me.session.logoPath$;
    me.fullName = me.session.fullName$;
    // get notification
    var toDate = new Date();
    var fromdate = new Date().setDate(toDate.getDate() - 7);

    me.fromDate = moment(fromdate).startOf("day").format("YYYY-MM-DD HH:mm:SS");
    me.toDate = moment(toDate).endOf("day").format("YYYY-MM-DD HH:mm:SS");

    me.accountDetails = me.session?.account;
    me.receiverId = me.accountDetails?.id;
  }

  ngAfterViewInit() {
    const me = this;
  }
  closeProfileDialog() {
    if (this.profileModal) {
      this.profileModal.closeDialog();
    }
  }
  // logout function
  logOut() {
    const me = this;
    me.session.logout().subscribe(
      (state: Store.State) => {
        if (state instanceof LogoutLoadingState) {
          me.onLoading(state);
          return;
        }

        if (state instanceof LogoutLoadedState) {
          me.onLoaded(state);
          return;
        }
      },
      (state: LogoutErrorState) => {
        me.onLoadingError(state);
      }
    );
  }

  private onLoading(state: LogoutLoadingState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = null;
    me.loading = true;
  }

  private onLoadingError(state: LogoutErrorState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = true;
    me.loading = true;
  }

  private onLoaded(state: LogoutLoadedState) {
    const me = this;
    if (state.data?.errorCode == 0) {
      me.data = state.data;
      me.error = false;
      me.loading = false;
      if (me.data) {
        me.router.navigate(["logout"]);
      }
    } else {
      me.loading = false;
      me.snakbarService.showToast(me.toast.nativeElement, {
        // title: errorMessage.error?.error?.errorCode,
        title: state?.data?.message,
        type: "ERROR",
        showCloseButton: true,
      });
    }
  }

  getClass(path: string) {
    // return window.location.pathname.substr(0, path.length) === path && !this.sideNav?.isOpen;
    return window.location.pathname.includes(path);
  }
  openProfileDialog() {
    this.profileModal.openDialog();
  }
}
